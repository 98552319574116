import { decodeHTMLEntities, parseHtml } from '@/utils/common';
import React, { ReactNode, useState } from 'react';
import styles from './style.module.scss';

interface TextProps {
  content: string | ReactNode;
  isHtml?: boolean;
  hasSymbols?: boolean;
  readMoreText?: string;
  readLessText?: string;
  width?: string;
  className?: string;
}

const TextExpand: React.FC<TextProps> = ({
  content,
  isHtml = false,
  readMoreText = 'Read More..',
  readLessText = 'Less',
  hasSymbols = false,
  width,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_TEXT_LENGTH = 50;
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  function clearDesc(desc: any) {
    if (typeof desc !== 'string') {
      desc = String(desc); // Convert non-string inputs to a string
    }
    return desc
      .replace(/\n/g, ' ')
      .replace(/<span class="more"><\/span>/g, '')
      .replace(/<span class="moreButton"><\/span>/g, '')
      .replace(/<span class="moreButton"><\/span>/g, '')
      .replace(
        /https:\/\/www\.woodenstreet\.com/g,
        process.env.NEXT_PUBLIC_BASE_URL
      )
      .replace(/&amp;/g, '&')
      .trim();
  }

  // const renderContent = (): ReactNode => {
  //   if (isHtml) {
  //     const parsedContent = clearDesc(parseHtml(decodeHTMLEntities(content)));
  //     if (Array.isArray(parsedContent)) {
  //       return <>{parsedContent}</>;
  //     }
  //     return parsedContent;
  //   }
  //   return content;
  // };

  const renderContent = (): ReactNode => {
    if (typeof content === 'string') {
      if (isHtml) {
        return parseHtml(decodeHTMLEntities(clearDesc(content)));
      }

      return clearDesc(content);
    }
    return clearDesc(content);
  };

  const isLongText =
    typeof content === 'string' && content.length > MAX_TEXT_LENGTH;

  return (
    <div className={styles['textExpandWrapper']}>
      <div
        className={`${styles.textContainer} ${isExpanded ? styles.expanded : ''} ${className ? styles[className] : ''}`}
        style={width ? { width: width } : {}}
      >
        {renderContent()}
        {isLongText && (
          <button
            className={`${styles['read-more-btn']} ${isExpanded ? styles['read-less'] : styles['read-more']} ${
              hasSymbols ? styles['has-symbols'] : ''
            }`}
            onClick={toggleExpansion}
            title={isExpanded ? readLessText : readMoreText}
            aria-label="Read more"
          >
            {hasSymbols ? '' : isExpanded ? readLessText : readMoreText}
          </button>
        )}

        {/* {hasSymbols ? (
          <button
            className={`${styles['read-more-btn']} ${isExpanded ? styles['read-less'] : styles['read-more']} ${styles['has-symbols']}`}
            onClick={toggleExpansion}
          ></button>
        ) : (
          <button
            className={`${styles['read-more-btn']} ${isExpanded ? styles['read-less'] : styles['read-more']}`}
            onClick={toggleExpansion}
          >
            {isExpanded ? readLessText : readMoreText}
          </button>
        )} */}
      </div>
    </div>
  );
};

export default TextExpand;

// import React, { useState } from 'react';

// import { decodeHTMLEntities, parseHtml } from '@/utils/common';

// interface TextProps {
//   text: string;
// }

// const TextExpand: React.FC<TextProps> = ({ text }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const toggleExpansion = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div className={styles['textExpandWrapper']}>
//       <div
//         className={`${styles.textContainer} ${isExpanded ? styles.expanded : ''}`}
//       >
//         {parseHtml(decodeHTMLEntities(text))}
//         <button
//           className={`${styles['read-more-btn']} ${isExpanded ? styles['read-less'] : styles['read-more']}`}
//           onClick={toggleExpansion}
//         ></button>
//       </div>
//     </div>
//   );
// };

// export default TextExpand;
